import { fetchCurrentUser } from '../../ducks/user.duck';
import { createAddress } from '../../util/api';

// Action types
const VALIDATE_ADDRESS_REQUEST = 'app/ShippingDetailsPage/VALIDATE_ADDRESS_REQUEST';
const VALIDATE_ADDRESS_SUCCESS = 'app/ShippingDetailsPage/VALIDATE_ADDRESS_SUCCESS';
const VALIDATE_ADDRESS_ERROR = 'app/ShippingDetailsPage/VALIDATE_ADDRESS_ERROR';
const SAVE_SHIPPING_DETAILS_REQUEST = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_REQUEST';
const SAVE_SHIPPING_DETAILS_SUCCESS = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_SUCCESS';
const SAVE_SHIPPING_DETAILS_ERROR = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_ERROR';
const CLEAR_SHIPPING_ERRORS = 'app/ShippingDetailsPage/CLEAR_SHIPPING_ERRORS';

// Initial state
const initialState = {
  saveInProgress: false,
  saveError: null,
  validationInProgress: false,
  validationErrors: null,
  validatedAddress: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VALIDATE_ADDRESS_REQUEST:
      return { ...state, validationInProgress: true, validationErrors: null };
    case VALIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        validationInProgress: false,
        validatedAddress: payload,
        validationErrors: null,
      };
    case VALIDATE_ADDRESS_ERROR:
      return { ...state, validationInProgress: false, validationErrors: payload };
    case SAVE_SHIPPING_DETAILS_REQUEST:
      return { ...state, saveInProgress: true, saveError: null };
    case SAVE_SHIPPING_DETAILS_SUCCESS:
      return { ...state, saveInProgress: false, validatedAddress: null, validationErrors: null };
    case SAVE_SHIPPING_DETAILS_ERROR:
      return { ...state, saveInProgress: false, saveError: payload };
    case CLEAR_SHIPPING_ERRORS:
      return { ...state, validationErrors: null, saveError: null };
    default:
      return state;
  }
}

// Action creators
const saveShippingDetailsRequest = () => ({ type: SAVE_SHIPPING_DETAILS_REQUEST });
const saveShippingDetailsSuccess = () => ({ type: SAVE_SHIPPING_DETAILS_SUCCESS });
const saveShippingDetailsError = error => ({
  type: SAVE_SHIPPING_DETAILS_ERROR,
  payload: error,
});
export const clearShippingErrors = () => ({ type: CLEAR_SHIPPING_ERRORS });

// Thunk

export const saveShippingDetails = values => async (dispatch, getState, sdk) => {
  dispatch(saveShippingDetailsRequest());
  // create address from shippo and validate it before saving
  console.log('values', values.privateData);

  const currentUser = getState().user.currentUser.attributes;
  const body = { values, currentUser };

  try {
    // Call the server-side endpoint to create and validate the Shippo address
    const response = await createAddress(body);

    if (response.status === 'error') {
      dispatch(saveShippingDetailsError(response.errors));
      return { errors: response.errors };
    }

    const { shippoAddressId } = response.data;

    await sdk.currentUser.updateProfile(
      {
        publicData: { shippoAddressId },
        privateData: values.privateData,
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    );

    dispatch(saveShippingDetailsSuccess());
    return { success: true };
  } catch (error) {
    console.error('Error saving shipping details:', error);
    dispatch(saveShippingDetailsError(error.message));
    throw error;
  }
};

// Load data function
export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
