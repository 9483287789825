import React from 'react';
import { FieldTextInput } from '../../components';
import { format, parse } from './e164Formatter';

// UK phone number regex
const ukPhoneRegex = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;

const FieldPhoneNumberInput = props => {
  const validateUKPhoneNumber = value => {
    if (!value) return 'Phone number is required';
    if (!ukPhoneRegex.test(value)) return 'Invalid UK phone number';
    return null;
  };

  const inputProps = {
    type: 'tel',
    format: format,
    parse: parse,
    validate: validateUKPhoneNumber,
    ...props,
  };

  return <FieldTextInput {...inputProps} />;
};

export default FieldPhoneNumberInput;
