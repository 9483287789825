import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';
export const recommendedSectionId = 'recommended-listings';
export const whatsSellingSectionId = 'whats-selling-listings';

// ================ Action types ================ //

export const FETCH_ASSETS_SUCCESS = 'app/LandingPage/FETCH_ASSETS_SUCCESS';
const FETCH_WHATS_SELLING_LISTINGS_REQUEST = 'app/FETCH_WHATS_SELLING_LISTINGS_REQUEST';
const FETCH_WHATS_SELLING_LISTINGS_SUCCESS = 'app/FETCH_WHATS_SELLING_LISTINGS_SUCCESS';
const FETCH_WHATS_SELLING_LISTINGS_ERROR = 'app/FETCH_WHATS_SELLING_LISTINGS_ERROR';

const FETCH_RECOMMENDED_LISTINGS_REQUEST = 'app/FETCH_RECOMMENDED_LISTINGS_REQUEST';
const FETCH_RECOMMENDED_LISTINGS_SUCCESS = 'app/FETCH_RECOMMENDED_LISTINGS_SUCCESS';
const FETCH_RECOMMENDED_LISTINGS_ERROR = 'app/FETCH_RECOMMENDED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  whatsSellingListings: [],
  recommendedListings: [],
  whatsSellingLoading: false,
  recommendedLoading: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        recommendedListingIds: payload.ids,
      };
    case FETCH_WHATS_SELLING_LISTINGS_REQUEST:
      return { ...state, whatsSellingLoading: true, error: null };
    case FETCH_WHATS_SELLING_LISTINGS_SUCCESS:
      return {
        ...state,
        whatsSellingLoading: false,
        whatsSellingListings: payload,
      };
    case FETCH_WHATS_SELLING_LISTINGS_ERROR:
      return { ...state, whatsSellingLoading: false, error: action.error };

    case FETCH_RECOMMENDED_LISTINGS_REQUEST:
      return { ...state, recommendedLoading: true, error: null };
    case FETCH_RECOMMENDED_LISTINGS_SUCCESS:
      return {
        ...state,
        recommendedLoading: false,
        recommendedListings: action.payload,
      };
    case FETCH_RECOMMENDED_LISTINGS_ERROR:
      return { ...state, recommendedLoading: false, error: action.error };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchAssetsSuccess = ids => ({
  type: FETCH_ASSETS_SUCCESS,
  payload: { ids },
});

export const fetchWhatsSellingListingsRequest = () => ({
  type: FETCH_WHATS_SELLING_LISTINGS_REQUEST,
});
export const fetchWhatsSellingListingsSuccess = listings => ({
  type: FETCH_WHATS_SELLING_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchWhatsSellingListingsError = error => ({
  type: FETCH_WHATS_SELLING_LISTINGS_ERROR,
  error,
});

export const fetchRecommendedListingsRequest = () => ({ type: FETCH_RECOMMENDED_LISTINGS_REQUEST });
export const fetchRecommendedListingsSuccess = listings => ({
  type: FETCH_RECOMMENDED_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchRecommendedListingsError = error => ({
  type: FETCH_RECOMMENDED_LISTINGS_ERROR,
  error,
});

const processListings = responseData => {
  const listings = responseData.data;
  const included = responseData.included || [];

  // Create mappings for images and users
  const imagesById = {};
  const usersById = {};
  const profileImagesById = {};

  included.forEach(item => {
    if (item.type === 'image') {
      imagesById[item.id.uuid] = item;
    } else if (item.type === 'user') {
      usersById[item.id.uuid] = item;
    }
  });

  // Map images and author names to listings
  const listingsWithDetails = listings.map(listing => {
    // Get author details
    const authorId = listing.relationships.author.data.id.uuid;
    let author = usersById[authorId];

    // Get author's profile image
    const profileImageRel = author.relationships?.profileImage?.data;
    let profileImage = null;

    if (profileImageRel) {
      const profileImageId = profileImageRel.id.uuid;
      profileImage = imagesById[profileImageId];
    }

    // Attach profileImage to author
    if (profileImage) {
      author = {
        ...author,
        profileImage,
      };
    }

    // Get listing images
    const imageRelationships = listing.relationships.images.data || [];
    const listingImages = imageRelationships.map(imgRel => imagesById[imgRel.id.uuid]);

    // Build the listing object with additional details
    return {
      ...listing,
      author,
      images: listingImages,
    };
  });

  return listingsWithDetails;
};

// ================ Thunks ================ //

export const fetchWhatsSellingListings = params => (dispatch, getState, sdk) => {
  dispatch(fetchWhatsSellingListingsRequest());

  return sdk.listings
    .query(params)
    .then(response => {
      const listingsWithDetails = processListings(response.data);
      dispatch(fetchWhatsSellingListingsSuccess(listingsWithDetails));
    })
    .catch(e => {
      dispatch(fetchWhatsSellingListingsError(storableError(e)));
    });
};

export const fetchRecommendedListings = params => (dispatch, getState, sdk) => {
  dispatch(fetchRecommendedListingsRequest());

  return sdk.listings
    .query(params)
    .then(response => {
      const listingsWithDetails = processListings(response.data);
      dispatch(fetchRecommendedListingsSuccess(listingsWithDetails));
    })
    .catch(e => {
      dispatch(fetchRecommendedListingsError(storableError(e)));
    });
};

export const getRecommendedListingParams = config => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;
  const listingFields = config?.listing?.listingFields;
  const conditionField = listingFields
    ? listingFields.find(field => field.key === 'condition_id')
    : null;

  return {
    include: ['author', 'publicData', 'author.profileImage', 'images'],
    minStock: 1,
    'fields.listing': [
      'title',
      'price',
      'publicData.transactionProcessAlias',
      ...(conditionField ? [`publicData.${conditionField.key}`] : []),
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData.username',
      'profile.imageId', // Include the profile image ID
    ],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    sort: '-price',
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig('square-small', 240, 1),
    ...createImageVariantConfig('square-small2x', 480, 1),
    'limit.images': 1,
    perPage: 4, // Limit the number of listings to 4
  };
};

export const getWhatsSellingListingsParams = config => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;
  const listingFields = config?.listing?.listingFields;
  const conditionField = listingFields
    ? listingFields.find(field => field.key === 'condition_id')
    : null;

  return {
    include: ['author', 'publicData', 'author.profileImage', 'images'],
    minStock: 1,
    'fields.listing': [
      'title',
      'price',
      'publicData.transactionProcessAlias',
      ...(conditionField ? [`publicData.${conditionField.key}`] : []),
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData.username',
      'profile.imageId', // Include the profile image ID
    ],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig('square-small', 240, 1),
    ...createImageVariantConfig('square-small2x', 480, 1),
    'limit.images': 1,
    perPage: 4, // Limit the number of listings to 4
    // sort price high to low
  };
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  return dispatch(fetchPageAssets(pageAsset, true)).then(assetResp => {
    dispatch(fetchAssetsSuccess());
  });
};
