import React from 'react';
import { bool, object, string } from 'prop-types';

import { intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { FieldTextInput, FieldSelect } from '../../components';

import css from './StripePaymentAddress.module.css';

const StripePaymentAddress = props => {
  const { className, intl, disabled, form, fieldId, card, locale } = props;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });

  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const handleOnChange = event => {
    const value = event.target.value;
    form.change('postal', value);
    card.update({ value: { postalCode: value } });
  };

  return (
    <div className={className ? className : css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.streetAddress`}
          name="streetAddress"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing streetAddress"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
          onUnmount={() => form.change('addressLine1', undefined)}
        />

        <FieldTextInput
          id={`${fieldId}.buildingNumber`}
          name="buildingNumber"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing buildingNumber"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('buildingNumber', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.postCode`}
          name="postal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing postCode"
          label={postalCodeLabel}
          placeholder={'SS1 2EH'}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postCode', undefined)}
          onChange={event => handleOnChange(event)}
        />

        <FieldTextInput
          id={`${fieldId}.city`}
          name="city"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={'London'}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.state`}
          name="County"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level1"
          label={'County • optional'}
          placeholder={'Enter your County'}
          onUnmount={() => form.change('state', undefined)}
        />
      </div>
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
  locale: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
